<template>
    <div>
        <div class="news-head-line">
        </div>
        <div class="container-1" style="margin-bottom:10rem">
            <img class="news-img" src="../../assets/image/news/5-5img.png"/>
            <div class="news-box">
                <div class="title">新起点，新征程 —— 可口可乐不含气业务系统顺利切换上线</div>
                <div class="date">2023/01/01</div>
                <div class="line"></div>
                <div class="content">2023新年伊始，中粮与太古完成收购可口可乐中国不含气饮料业务。而就在前一天（即22年12月31日），在中粮及太古领导的大力支持下，
                    上海比孚信息科技有限公司（简称比孚）作为项目的实施方，成功将相关业务系统从原先的可口可乐装瓶商（SCMC）顺利切换到中粮和太古可口可乐麾下。<br/><br/>

                    
                    本次切换涉及多是中可和太古的业务核心系统，包括SAP，生产数字化和原材料管理系统等，以及中可和太古在全国的超过20家工厂，影响重大。而另一方面，为了不影响各家工厂的正常业务运转，留出的窗口却仅仅一天时间。项目全程得到了客户和公司领导的充分支持，面对巨大的困难和挑战，团队抱着迎难而上的决心，以敢啃“硬骨头”的勇气，始终保持着高昂的战斗力，把过程中每一件事情做正确。<br/><br/>

                    项目组在前期做好了充分的准备及周密的安排和部署。所谓“逢山开路，遇水架桥”，再大的困难都会迎刃而解。在切换当天，项目组与业务用户紧密配合，加班加点，始终保持着有效的沟通，各部门明确分工职责，有序对接，稳步推进，终于在2022年最后一天成功切换上线，为魔幻的这一年画上了圆满的句号。
                    <div style="display:flex;justify-content: space-between;width: 100%;">
                        <div class="insert-img">
                        <img src="../../assets/image/news/news5-1.png"/> 
                        </div>
                        <div class="insert-img">
                        <img src="../../assets/image/news/news5-2.png"/> 
                        </div>
                    </div>
                    <div class="img-title">图1：项目会议</div>
                    <div class="small-title1"> 太古可口可乐SAP系统</div>
                    SAP系统在企业业务管理中充当着极其关键的作用，本次切换包括SAP中诸多模块（例如：MM，SD，PP，FI，CO，HR，BW，ABAP，BASIS，CE-BF，CE-WMS等），涉及数据量巨大，数据类型繁多，项目难度和复杂程度相当高。<br/><br/>
                    在项目准备过程中，通过与客户多次讨论切换方案，评估和分析了各方案的优缺点。为了模拟各种突发或特殊情况发生，项目组通过三番五次的操练和测试，验证各种解决方案，并熟练掌握了各项应对措施。<br/><br/>
                    在项目切换过程中，项目成员通宵达旦，按计划有序推进，对每个步骤仔细检查，争取100%的正确率。<br/><br/>
                    目前，SCMC现有业务运作系统环境已成功切换为太古环境，简称为TSA阶段系统，将继续支持不含气饮料产品持续生产供应运作。接下来SCMC ME售卖交易到太古ME的原材料库存将自动过渡到太古ME原材料库存。SAP系统中，不含气饮料产品生产供应运作功能，业务流程，以及使用环境都将保持不变，直到2023年2月份业务运作全面融入到太古可口可乐系统运作体系结束。在此期间SAP系统将继续一如既往地全力支持不含气饮料产品正常的生产供应和运作<br/><br/>
                    <div style="display:flex;justify-content: space-between;width: 100%;">
                        <div class="insert-img">
                        <img src="../../assets/image/news/news5-3.png"/> 
                        </div>
                        <div class="insert-img">
                        <img src="../../assets/image/news/news5-4.png"/> 
                        </div>
                    </div>
                    <div class="img-title">图2：项目团队与关键用户线上开会</div>
                    <div class="small-title1"> 中粮可口可乐生产数字化系统（MDS）</div>
                    由于可口可乐装瓶商、中粮可口可乐（简称中可）以及集团业务重组，原来营口、石家庄、北京、济南、成都、长沙厂的产线数字化系统（TAOTIE）更名为生产数字化系统（MDS）。<br/><br/>
                    在项目初期，结合中可的业务需求，比孚对TAOTIE系统进行全面的诊断和分析，制定了项目的整体规划和方向。<br/><br/>
                    在项目准备过程中，比孚始终以保证企业网络通信安全为前提，以实现原TAOTIE系统所有业务功能成功对接至中可为目的，稳定高效的推进项目。由于中可对网络通信安全的要求极高，在双方网络专家的几番讨论之后，最终确定比孚与中可内网之间使用点对点方式访问，整个系统中任何一个通道不同，都会对系统功能产生极大的影响。因此如何确保MDS STILL系统的每个接口都能成功的与对方取得通信，成为本项目的难点之一。<br/><br/>
                    项目历时四个月时间，先后完成网络通讯方案规划、外围系统对接方案设计、内部业务逻辑变更及上线迁移计划和多次演练，为系统正式切换做了充分的准备。<br/><br/>
                    <div style="display:flex;justify-content: space-between;width: 100%;">
                        <div class="insert-img">
                        <img src="../../assets/image/news/news5-5.png"/> 
                        </div>
                        <div class="insert-img">
                        <img src="../../assets/image/news/news5-6.png"/> 
                        </div>
                    </div>
                    <div class="img-title">图3：项目团队稳步推进</div>

                    上线切换期间，在中可网络组和各工厂IT的大力支持下，成功将工厂侧服务器由SCMC切换至中可网络。同时MDS STILL系统也顺利完成与厂侧服务器、SAP、外围系统的对接，最终实现MDS产线数字化系统的成功切换。接来下比孚科技也将全力支持MDS STILL系统上线后的运维工作。<br/><br/>

                    目前，MDS STILL产线数字化系统已成功在中可环境内运行。系统基于IoT自动采集产线、产程、设备、能耗等数据，实时监控生产状况；可视化看板及报表工具、故障反馈机制提高了人员效率、降低管理成本；基于数据驱动，结合生产关键指标（生产效率、订单完成率等）及排序分析，实现了生产运营的预测和决策。<br/><br/>

                    <div class="small-title1"> 中粮可口可乐原材料管理系统（PW-WMSR）</div>
                    自2022年8月项目启动，经过4个月的系统设计开发，12月初WMSR原材料管理系统具备符合新业务的上线条件。<br/><br/>
                    在前期用户培训及切换演练等充足准备下，比孚项目组与客户团队紧密配合，经过为期2天的库存盘点及系统切换，2022年12月31日WMSR成功上线，并于2023年1月1日伴随生产开机投入使用。<br/><br/>
                    此次WMSR原材料管理系统的成功上线，实现了中粮可口可乐原材料管理信息网与过程控制网的一体化统筹。项目的成功为企业提供仓储物流、生产制造等各类数据信息，实现数据从上游采购到下游工厂生产的双向无缝对接，有效消除信息孤岛，完成原材料管理的一体化管控、全过程信息追溯。<br/><br/>

                    <div class="small-title0"> 总结：</div>
                    此次系统成功上线得到了可口可乐SCMC的高度认可，SCMC IT总监Rick说：“感谢大家的努力和付出！祝大家2023年一帆风顺，大展宏图” ，SCMC IT高级经理王小峰说：“项目的成功上线离不开大家的辛苦付出，这里感谢大家的努力和拼搏，我们成功收获胜利的果实。”<br/><br/>

                    比孚着眼于为饮料制造业提供供应链解决方案和智能工厂统一规划布局。作为饮料行业的两大巨头，此次中粮和太古不含气业务系统成功切换上线，将会为行业企业数字化和智能化转型升级树立一个重要里程碑。未来，比孚将持续创新、不断优化行业解决方案，助力企业实现更高效快捷的运营和发展，为企业数字化转型产业发展赋能！<br/><br/>

                    比孚将始终以“成为快消零售医药领域接触的IT技术咨询公司”为企业发展愿景，为优秀企业提供优质、全面的企业信息科技服务，力求与广大客户与合作伙伴达到共赢、共同发展的目标。新的一年，比孚也将全力以赴，加强公司技术创新能力以及科技成果转化能力，为企业持续、健康、快速发展提供强有力的技术支撑，助力企业稳步向前。<br/><br/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import store from '@/plugins/store';
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {
        setTimeout(()=>{
            this.refreshIndexInfo({data:'5' , news:true});
        },500)
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
    },
};
</script>

<style lang="less" scoped>
.news-head-line{
    width: 190rem;
    border: 1px solid #F3FAF6;
    margin-top: 11rem;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
}
.news-img{
    width: 100rem;
    height: 30rem;
    margin-top: 4rem;
}
.news-box{
    width: 100rem;
    // height: 130rem;
    margin: auto;
    margin-top: 6rem; 
    .title{
        height: 2.6rem;
        font-size: 2.6rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.9rem;
        text-align: justify;
    }
    .date{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.4rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .line{
        width: 100rem;
        height: 1px;
        border: 1px solid #EDEDED;
        margin-top: 1rem;
    }
    .content{
        width: 100.1rem;
        // height: 46.7rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 3rem;
        margin-top: 2.4rem;
        text-align: justify;
        .insert-img{
            margin-top: 2rem;
            margin-bottom: 2rem;
            img{
                width: 47rem;
                height: 30rem;
                display: flex;
                margin: auto;
            }
            .insert-title{
                font-size: 1.6rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #999999;
                line-height: 2.4rem;
                text-align: center;
                margin-top: 2rem;
            }
        }
        .img-title{
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #999999;
            line-height: 2.4rem;
            text-align: center;
            width: 100%; 
            margin-bottom: 2rem;
        }
        .small-title0{
            font-size: 2.2rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #000000;
            line-height: 2.4rem;
            margin-bottom: 2rem;
        }
        .small-title1{
            font-size: 1.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #000000;
            line-height: 2.4rem;
            margin-bottom: 2rem;
        }
    }
}
</style>